<template>
  <v-container class="d-flex flex-column pa-8">
    <v-card-text>
      <div class="cont-error" v-if="state.status == 'error'">
        <h2>Error</h2>
        <div class="data-info">
          <div class="domain-list">
            <a
              v-if="state.report.keyword"
              :href="googleLink(state.report.keyword)"
              target="_blank"
              class="col-keyword"
              >{{ state.report.keyword }}</a
            >
          </div>
          <div>
            <div class="domain-list">
              <a
                v-for="(url, index) in state.report.comp_urls"
                :key="index"
                :href="externalLink(url)"
                target="_blank"
                class="url-domain"
                >{{ url }}</a
              >
            </div>
          </div>
        </div>
        <h2 class="text-center">Message</h2>
        <div class="cont-error-meesage">{{ state.message }}</div>
      </div>

      <div class="cont-error" v-if="state.status == 'failed'">
        <h2>Error</h2>
        <div class="data-info">
          <p>{{ state.message }}</p>
        </div>
      </div>

      <div class="await-content" v-else>
        <h2 class="view-title">Your Report is Being Created!</h2>
        <div class="await-cont-text">
          <span>
            Our research engine is already collecting and analyzing the data necessary for your report(s). We will
            notify you via email and in-app notifications as soon as your report(s) are ready.
          </span>
          <span>We guarantee it will be worth the wait!</span>
        </div>
      </div>
    </v-card-text>
  </v-container>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  data() {
    return {
      loading: true,
      data: {},
      interval: '',
      state: {},
    }
  },
  mounted() {
    this.getKwrData()
  },
  beforeDestroy() {
    clearInterval(this.interval)
  },
  externalLink(url) {
    if (url.includes('https://') || url.includes('http://')) {
      return url
    } else {
      return 'https://' + url
    }
  },

  methods: {
    ...mapActions({
      getKwr: 'keyword_research/getKwr',
      check: 'keyword_research/checkStatus',
    }),
    goToPath(path) {
      this.$router.push({ path })
    },
    async getKwrData() {
      await this.getKwr(
        new URLSearchParams({
          ...this.$route.params,
        }).toString(),
      )
        .then(resp => {
          if (resp && resp.data) {
            this.data = resp.data
            this.getStatus(resp.data.kwr)
            this.toDo()
          }
        })
        .catch(err => {})
    },
    async getStatus(kwr) {
      await this.check(
        kwr,
        new URLSearchParams({
          ...this.$route.params,
        }).toString(),
      )
        .then(resp => {
          if (resp && resp.data) {
            this.state = resp.data
            if (resp.data.progress == 'completed') {
              this.loading = false
              clearInterval(this.interval)
              let path = '/kw-research/report/' + resp.data.kwr.id
              this.$router.push({ path })
            }
            if (resp.data.progress == 'error' || resp.data.progress == 'failed') {
              this.loading = false
              clearInterval(this.interval)
            }
          }
        })
        .catch(err => {})
    },
    toDo() {
      const kwr = this.data.kwr
      const getState = this.getStatus
      this.interval = setInterval(function () {
        getState(kwr)
      }, 5000)
    },
  },
}
</script>

<style scoped>
.await-content {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

.cont-error {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  text-align: center;
}
.text-bold {
  font-weight: 700;
}
.await-cont-text {
  max-width: 700px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

.col-keyword {
  max-width: 200px !important;
  word-break: break-word;
}
.url-domain {
  max-width: 300px !important;
  word-break: break-all !important;
}
.domain-list {
  display: flex;
  flex-direction: column;
}

@media (max-width: 992px) {
  .col-keyword {
    min-width: 200px;
  }
  .url-domain {
    min-width: 300px;
  }
}
</style>
